import React, { useState } from "react";
import axios from "axios";
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Container,
    Grid,
    Divider
} from '@mui/material';

const PaymentPage = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        cardNumber: "",
        expiryDate: "",
        securityCode: ""
    });

    const mycourseName = localStorage.getItem('mycourseName');
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePayment = async () => {
        try {
            const response = await axios.post("http://localhost:5000/api/pay", formData);
            if (response.data.success) {
                alert("Pago realizado exitosamente. ¡Gracias!");
            } else {
                alert(response.data.message || "Error al procesar el pago.");
            }
        } catch (error) {
            console.error(error);
            alert("Error procesando el pago. Inténtelo de nuevo.");
        }
    };

    const openWhatsApp = () => {
        const phoneNumber = "+51969206540"; // Número de WhatsApp de la empresa
        const message = encodeURIComponent("Hola, necesito ayuda con el pago del certificado.");
        window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
    };

    const handlePaypalPayment = () => {
        // Aquí puedes agregar la integración de PayPal (por ejemplo, usando la SDK de PayPal)
        alert("Redirigiendo a PayPal para completar el pago...");
    };

    return (
        <Container maxWidth="lg" sx={{ paddingY: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
                Para Obtener Certificado de: <strong>{mycourseName}</strong>
            </Typography>
            <Typography variant="body1" align="center" paragraph>
                El certificado será emitido validado y respaldado por STP.
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
                
                {/* Payment Form Section */}
                <Paper sx={{ p: 3, flex: 1 }}>
                    <Typography variant="h5" gutterBottom>
                        Métodos de pago
                    </Typography>

                    <Typography variant="h6" gutterBottom>
                        Información del propietario
                    </Typography>
                    {['firstName', 'lastName', 'address', 'city', 'province', 'postalCode'].map((field) => (
                        <TextField
                            key={field}
                            label={capitalizeFirstLetter(field)}
                            name={field}
                            value={formData[field]}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    ))}

                    <Typography variant="h6" gutterBottom>
                        Información de la tarjeta de crédito
                    </Typography>
                    {['cardNumber', 'expiryDate', 'securityCode'].map((field) => (
                        <TextField
                            key={field}
                            label={capitalizeFirstLetter(field)}
                            name={field}
                            value={formData[field]}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    ))}

                    <Button 
                        variant="contained" 
                        color="primary" 
                        fullWidth 
                        onClick={handlePayment}
                        sx={{ mt: 2 }}
                    >
                        Pagar con Tarjeta
                    </Button>

                    <Divider sx={{ my: 2 }} />

                    <Button 
                        variant="contained" 
                        color="secondary" 
                        fullWidth 
                        onClick={handlePaypalPayment}
                        sx={{ mt: 2 }}
                    >
                        Pagar con PayPal
                    </Button>
                </Paper>

                {/* Help Section */}
                <Paper sx={{ p: 3, flex: 1 }}>
                    <Typography variant="h6" gutterBottom>
                        ¿Necesitas ayuda?
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Puedes pagar con depósito a nuestras cuentas de:
                        <ul>
                            <li>BCP</li>
                            <li>Interbank</li>
                            <li>Yape</li>
                        </ul>
                        Para obtener las cuentas y ser guiado en el proceso de pago, por favor escríbenos al WhatsApp:
                    </Typography>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={openWhatsApp}
                        fullWidth
                    >
                        Contactar por WhatsApp
                    </Button>
                </Paper>
            </Box>

            <Box sx={{ mt: 4 }}>
                <Typography variant="body2" align="center" color="textSecondary">
                    El precio del certificado es de:
                    <ul>
                        <li>Perú: S/ 70.00</li>
                        <li>Otro país: $ 20.00</li>
                    </ul>
                </Typography>
            </Box>
        </Container>
    );
};

// Helper function to capitalize the first letter of strings
const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/([A-Z])/g, ' $1');
};

export default PaymentPage;
