// src/pages/AccountSettings.js
import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Card, CardContent, Grid, CircularProgress, CardMedia } from '@mui/material';
import { Container, Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const AccountSettings = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const navigate = useNavigate();

  // Datos del usuario desde localStorage (en un caso real, esto sería desde un API)
  const userName = localStorage.getItem('userName');
  const userLastName = localStorage.getItem('userLastName');
  const userId = localStorage.getItem('userId');
  const userEmail = localStorage.getItem('userEmail'); // Se asume que el correo está almacenado

  useEffect(() => {
    // Simular carga de datos del usuario
    setName(`${userName} ${userLastName}`);
    setEmail(userEmail);

    // Obtener los cursos en los que el usuario está inscrito desde el backend
    fetch(`/user/${userId}/enrolled-courses`)
      .then((response) => response.json())
      .then((data) => {
        setEnrolledCourses(data.enrolled_courses);
        setLoading(false);
      })
      .catch((err) => {
        setError('Error al cargar los cursos.');
        setLoading(false);
      });
  }, [userName, userLastName, userEmail, userId]);

  const handleSave = () => {
    setLoading(true);
    setError('');
    setSuccess('');

    // Validación simple de campos
    if (!name || !email) {
      setError('Todos los campos son obligatorios');
      setLoading(false);
      return;
    }

    // Simulación de guardar cambios (en un entorno real, realizarías una solicitud al backend)
    setTimeout(() => {
      setSuccess('¡Cambios guardados con éxito!');
      setLoading(false);
    }, 1500);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 3 }}>
        Configuración de Cuenta
      </Typography>

      <Card sx={{ marginBottom: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Información Personal
          </Typography>

          {error && (
            <Typography color="error" variant="body2" sx={{ marginBottom: 2 }}>
              {error}
            </Typography>
          )}
          {success && (
            <Typography color="primary" variant="body2" sx={{ marginBottom: 2 }}>
              {success}
            </Typography>
          )}

          <TextField
            label="Nombre Completo"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSave}
            sx={{ padding: '10px', textTransform: 'none' }}
          >
            Guardar Cambios
          </Button>
        </CardContent>
      </Card>

      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Mis Cursos
      </Typography>

      {loading ? (
        <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
      ) : (
        <Grid container spacing={3}>
          {enrolledCourses.length > 0 ? (
            enrolledCourses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id}>
                <Card sx={{ height: '100%' }}>
                  {course.image && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={course.image}
                      alt={course.course_name}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {course.course_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                      {course.course_type} - {course.weeks} semanas
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: 2 }}>
                      {course.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate(`/course/${course.id}`)} // Redirigir al curso
                      sx={{ textTransform: 'none' }}
                    >
                      Ver Curso
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              No estás inscrito en ningún curso actualmente.
            </Typography>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default AccountSettings;
