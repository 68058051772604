import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box, List, ListItem, ListItemText, Grid, Paper, Divider, Avatar } from '@mui/material';
import { Chat } from '@mui/icons-material';
import axios from 'axios';

const CourseDetail = () => {
  const userId = localStorage.getItem('userId');
  const courseId = localStorage.getItem('courseId');
  const courseName = localStorage.getItem('courseName');
  const description = localStorage.getItem('courseDes');
  const weeks = localStorage.getItem('courseWeek');
  const type = localStorage.getItem('courseType');
  const imagen = localStorage.getItem('imagen');
  const sumilla = localStorage.getItem('sumilla');
  const competencia = localStorage.getItem('competencia');
  const profesor = localStorage.getItem('profesor');

  const navigate = useNavigate();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [sections, setSections] = useState([]);
  const [classes, setClasses] = useState([]);
  const [professor, setProfessor] = useState(null);

  useEffect(() => {
    const checkEnrollment = async () => {
      try {
        const response = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/courses/${courseId}/check-enrollment`, {
          params: { user_id: userId }
        });

        if (response.data.isEnrolled) {
          setIsEnrolled(true);
        }
      } catch (error) {
        console.log('Error al verificar la inscripción:', error);
      }
    };

    const fetchSectionsAndClasses = async () => {
      try {
        const response = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/api/sections/${courseId}`);
        setSections(response.data);
        const classResponses = await Promise.all(response.data.map(section =>
          axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/api/classes/${section.id}`)
        ));
        const classesBySection = classResponses.map(res => res.data);
        setClasses(classesBySection);
      } catch (error) {
        console.log('Error al obtener las secciones y clases:', error);
      }
    };

    const fetchProfessor = async () => {
      setProfessor({
        name: profesor,
        bio: 'Experto en educación digital con más de 10 años de experiencia en enseñanza online.',
        avatar: 'https://example.com/professor-avatar.jpg'
      });
    };

    checkEnrollment();
    fetchSectionsAndClasses();
    fetchProfessor();
  }, [courseId, userId]);

  const handleEnroll = async () => {
    try {
      const response = await axios.post(`https://universidadvirtualdelperu.edu.pe/myapp/courses/${courseId}/enroll`, {
        user_id: userId,
      });
      if (response.status === 200) {
        setIsEnrolled(true);
        navigate('/courses');
      } else {
        console.log('Error al inscribirse en el curso:', response.data.message);
      }
    } catch (error) {
      console.log('Error al inscribirse en el curso:', error);
    }
  };

  const handleEnroll2 = () => {
    navigate('/login');
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h3" color="primary" sx={{ textAlign: 'center', marginBottom: 4 }}>
        {courseName}
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={imagen}
              alt={courseName}
              style={{
                width: '100%',
                maxWidth: '350px',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {description}
          </Typography>
          <Typography variant="h6">Duración: {weeks} semanas</Typography>
          <Typography variant="h6">Tipo: {type}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 4 }}>
        {!userId ? (
          <Button
            variant="contained"
            color="secondary"
            sx={{ marginTop: 2, width: '100%' }}
            onClick={handleEnroll2}
          >
            Inicia Sesión
          </Button>
        ) : (
          <Button
            variant="contained"
            color={isEnrolled ? 'primary' : 'secondary'}
            sx={{ marginTop: 2, width: '100%' }}
            onClick={handleEnroll}
            disabled={isEnrolled}
          >
            {isEnrolled ? 'Ya estás inscrito' : 'Inscríbete'}
          </Button>
        )}
      </Box>

      <Grid container spacing={4} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', marginBottom: 2, color: '#00695c' }}
            >
              Temario del Curso
            </Typography>
            <Box
              sx={{
                maxWidth: '100%',
                margin: '0 auto',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: 2,
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            >
              {sections.map((section, index) => (
                <Paper
                  key={section.id}
                  sx={{
                    padding: 3,
                    marginBottom: 3,
                    backgroundColor: '#f1f1f1',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ marginBottom: 2, color: '#004d40' }}
                  >
                    Sección {index + 1}: {section.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ marginBottom: 2, color: '#607d8b' }}
                  >
                    {section.description}
                  </Typography>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Typography variant="h6" sx={{ color: '#004d40' }}>
                    Clases de esta sección:
                  </Typography>
                  <List>
                    {classes[index]?.map((clase) => (
                      <ListItem
                        key={clase.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#e0f2f1',
                          marginBottom: 1,
                          borderRadius: '8px',
                          padding: '8px',
                        }}
                      >
                        <ListItemText
                          primary={clase.title}
                          sx={{
                            fontSize: '1.1rem',
                            fontWeight: 'bold',
                            color: '#00695c',
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              ))}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              padding: 3,
              backgroundColor: '#e8f5e9',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            }}
          >
            <Typography variant="h4" sx={{ marginBottom: 2, color: '#1b5e20' }}>
              Sumilla
            </Typography>
            <Typography
      variant="body1"
      sx={{
        lineHeight: 1.8,
        color: '#37474f', // Gris oscuro para un texto fácil de leer
        textAlign: 'justify', // Justifica el texto para mayor elegancia y legibilidad
      }}
    >
        {sumilla}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 4, backgroundColor: '#eef2f7', padding: 3, borderRadius: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Conoce al Profesor
        </Typography>
        {professor && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={professor.avatar}
              alt={professor.name}
              sx={{ width: 80, height: 80, marginRight: 2 }}
            />
            <Box>
              <Typography variant="h6">{professor.name}</Typography>
              <Typography variant="body2">{professor.bio}</Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          marginTop: 4,
          padding: 3,
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
         
          
          boxShadow: '0 4px 15px hsla(150, 87.50%, 34.50%, 0.44)',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2,fontWeight: 'bold',
      textTransform: 'uppercase'}}>
          ¿Tu empresa quiere participar con todo el equipo?
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Si deseas que tu empresa capacite a varios empleados, contáctanos para obtener un descuento especial en los certificados.
        </Typography>
        <Button
    variant="contained"
    sx={{
      backgroundColor: '#4caf50',
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '1rem',
      padding: '10px 20px',
      '&:hover': {
        backgroundColor: '#388e3c',
      },
    }}
    
          href="https://wa.me/+51987654321"
          target="_blank"
        >
          <Chat sx={{ marginRight: 1 }} />
          Ir a WhatsApp
        </Button>
      </Box>
    </Box>
  );
};

export default CourseDetail;
