import React, { useState, useEffect } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Select from 'react-select';
import { Card, CardContent, Typography, Grid, Box, Chip } from '@mui/material';
import { format } from 'date-fns';

// Registramos los componentes de Chart.js que vamos a usar
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GeneralDash = () => {
  const [data, setData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ start: null, end: null });
  const [places, setPlaces] = useState([]);
  const [alarmSummary, setAlarmSummary] = useState({
    totalAlarmsByMonth: {},
    alarmsToday: 0,
    alarmsByDay: {},
    alarmsByPlace: {},
  });

  const [filter, setFilter] = useState('all'); // Estado para el filtro de lugares (CPE vs otros)

  // Fetch de los datos desde el backend
  useEffect(() => {
    fetch("https://universidadvirtualdelperu.wilfredofanola.com/appiA2C/ServerA2CBP")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        const uniquePlaces = [...new Set(data.map(item => item.place))];
        setPlaces(uniquePlaces.map(place => ({ value: place, label: place })));
        const summary = calculateAlarmSummary(data);
        setAlarmSummary(summary);
      })
      .catch((error) => console.error('Error al obtener los datos:', error));
  }, []);

  // Función para calcular el resumen de alarmas
  // Función para calcular el resumen de alarmas
const calculateAlarmSummary = (data) => {
  const alarmsByDay = {};
  const alarmsByMonth = {};
  const alarmsByPlace = { CPE: 0, nonCPE: 0 }; // Inicializamos las alarmas de CPE y no-CPE
  let alarmsToday = 0;

  const today = new Date().toISOString().split('T')[0]; // Fecha de hoy en formato YYYY-MM-DD

  data.forEach(item => {
    const day = getDayOfWeek(item.date);
    const month = getMonth(item.date);
    const place = item.place;
    const date = item.date.split(' ')[0]; // Solo la parte de la fecha

    // Alarmas por Día
    alarmsByDay[date] = (alarmsByDay[date] || 0) + 1;

    // Alarmas por Mes
    alarmsByMonth[month] = (alarmsByMonth[month] || 0) + 1;

    // Alarmas por Lugar (CPE y no-CPE)
    if (place.startsWith('CPE')) {
      alarmsByPlace.CPE = (alarmsByPlace.CPE || 0) + 1;
    } else {
      alarmsByPlace.nonCPE = (alarmsByPlace.nonCPE || 0) + 1;
    }

    // Alarmas del Día Actual
    if (date === today) {
      alarmsToday++;
    }
  });

  return {
    totalAlarmsByMonth: alarmsByMonth,
    alarmsToday,
    alarmsByDay,
    alarmsByPlace,
  };
};

  // Función para obtener el día de la semana a partir de una fecha
  const getDayOfWeek = (date) => {
    const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const day = new Date(date).getDay();
    return daysOfWeek[day];
  };

  // Función para obtener el mes de una fecha
  const getMonth = (date) => {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const month = new Date(date).getMonth();
    return monthNames[month];
  };

  // Función para obtener la fecha formateada
  const getFormattedDate = (date) => {
    return format(new Date(date), 'dd MMM yyyy');
  };

  // Función para filtrar los datos según el lugar y el rango de fechas
  const filterData = () => {
    return data.filter(item => {
      const isInPlace = selectedPlace ? item.place === selectedPlace : true;
      const isInDateRange = selectedDateRange.start && selectedDateRange.end ? 
        new Date(item.date) >= new Date(selectedDateRange.start) && new Date(item.date) <= new Date(selectedDateRange.end) : true;
      return isInPlace && isInDateRange;
    });
  };

  // Preparar los datos para el gráfico de barras (Alarmas por Fecha)
  const barChartData = () => {
    const filteredData = filterData();
    const dayCounts = {};
    filteredData.forEach(item => {
      const day = getDayOfWeek(item.date);
      dayCounts[day] = (dayCounts[day] || 0) + 1;
    });
    return {
      labels: Object.keys(dayCounts),
      datasets: [
        {
          label: 'Cantidad de Alarmas por Día',
          data: Object.values(dayCounts),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  // Preparar los datos para el gráfico de líneas (Alarmas por Fecha por Place)
 // Función para generar tonos de azul
const getBlueShade = (index) => {
  const blueShades = [
    '#1E90FF', // Azul claro
    '#4682B4', // Azul medio
    '#5F9EA0', // Azul verdoso
    '#6495ED', // Azul de la cornflower
    '#00BFFF', // Azul cielo profundo
  ];
  return blueShades[index % blueShades.length]; // Ciclamos entre los tonos
};

// Función para generar tonos de rojo
const getRedShade = (index) => {
  const redShades = [
    '#FF6347', // Rojo tomate
    '#FF4500', // Naranja rojo
    '#DC143C', // Rojo crimson
    '#B22222', // Rojo fuego
    '#FF0000', // Rojo puro
  ];
  return redShades[index % redShades.length]; // Ciclamos entre los tonos
};

// Función para generar los datos del gráfico de líneas con tonos dinámicos
const lineChartDataByPlace = () => {
  const filteredData = filterDataByPlacePrefix(data, filter);
  const dateCountsByPlace = {};

  filteredData.forEach(item => {
    const date = getFormattedDate(item.date); // Convertir la fecha a formato 'dd MMM yyyy'
    const place = item.place;

    // Inicializar los datos para cada lugar
    if (!dateCountsByPlace[place]) {
      dateCountsByPlace[place] = {};
    }

    // Contar alarmas por lugar y fecha
    if (!dateCountsByPlace[place][date]) {
      dateCountsByPlace[place][date] = 0;
    }
    dateCountsByPlace[place][date]++;
  });

  // Generar los datos para el gráfico
  const labels = Object.keys(filteredData.reduce((acc, item) => {
    const date = getFormattedDate(item.date);
    acc[date] = true;
    return acc;
  }, {})); // Obtener todas las fechas únicas

  const datasets = Object.keys(dateCountsByPlace).map((place, index) => {
    const isCPE = place.startsWith('CPE');
    const color = isCPE ? getBlueShade(index) : getRedShade(index); // Usar tonos de azul o rojo dependiendo del lugar

    return {
      label: place,
      data: labels.map(date => dateCountsByPlace[place][date] || 0),
      fill: false,
      borderColor: color, // Asignar color basado en el lugar
      tension: 0.1,
    };
  });

  return {
    labels: labels,
    datasets: datasets,
  };
};

// Opciones para el gráfico de líneas con leyenda a la derecha y colores dinámicos
const lineChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'bottom', // Coloca la leyenda a la derecha
      labels: {
        usePointStyle: true, // Usar estilo de puntos para las leyendas
        boxWidth: 15, // Ancho del cuadro de color de la leyenda
      },
    },
  },
  
  layout: {
    padding: {
      bottom: 30, // Da un poco de espacio debajo para que no se superponga
    },
  },scales: {
    x: {
      title: {
        display: true,
        text: 'Fecha', // Título para el eje X
      },
    },
    y: {
      title: {
        display: true,
        text: 'Cantidad de Alarmas', // Título para el eje Y
      },
    }}
};

  
  
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const filterDataByPlacePrefix = (data, filter) => {
    if (filter === 'CPE') {
      return data.filter(item => item.place.startsWith('CPE'));
    } else if (filter === 'non-CPE') {
      return data.filter(item => !item.place.startsWith('CPE'));
    }
    return data;
  };

  // Opciones del filtro
  const options = [
    { value: 'all', label: 'Todos' },
    { value: 'CPE', label: 'CPE' },
    { value: 'non-CPE', label: 'Estaciones Base' }
  ];

  // Cambiar valor del filtro
  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption ? selectedOption.value : 'all');
  };




  
  return (
    <div style={{ padding: '30px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom>
        Registro General de Alarmas
      </Typography>

      {/* Estadísticas */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
      <Grid item xs={6} md={4}>
          <Card variant="outlined" sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Alarmas en el Mes
              </Typography>
              <Typography variant="h4">
                {Object.values(alarmSummary.totalAlarmsByMonth).reduce((a, b) => a + b, 0)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        
        
        <Grid item xs={6} md={4}>
  <Card variant="outlined" sx={{ boxShadow: 3, padding: 2 }}>
    <CardContent>
      <Typography variant="h6">Alarmas CPE</Typography>
      <Typography variant="h4">{alarmSummary.alarmsByPlace.CPE || 0}</Typography>
    </CardContent>
  </Card>
</Grid>
<Grid item xs={6} md={4}>
  <Card variant="outlined" sx={{ boxShadow: 3, padding: 2 }}>
    <CardContent>
      <Typography variant="h6">Alarmas Base</Typography>
      <Typography variant="h4">{alarmSummary.alarmsByPlace.nonCPE || 0}</Typography>
    </CardContent>
  </Card>
</Grid>
        
      </Grid>

      {/* Filtro de Place */}
      <Grid container spacing={4} style={{ marginTop: '20px' }}>
        <Grid item xs={12} md={10}>
          <Box sx={{ width: '100%' }}>
            <Select
              value={options.find(option => option.value === filter)}
              onChange={handleFilterChange}
              options={options}
              placeholder="Selecciona un filtro"
              isClearable
            />
          </Box>
        </Grid>

        {/* Gráfico de Alarmas por Fecha por Place */}
        <Grid item xs={12} md={12}>
  <Box variant="outlined" sx={{ width: '100%',boxShadow: 3 }}>
    <Box sx={{ width: '100%' }}>
      <Line data={lineChartDataByPlace()} options={lineChartOptions} />
    </Box>
  </Box>
</Grid>
      </Grid>
    </div>
  );
};

export default GeneralDash;
